@import "./variables";

@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-SemiBold;
  src: url(../fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: Poppins-Bold;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: Poppins-Light;
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: Poppins-Thin;
  src: url(../fonts/Poppins-Thin.ttf);
}
@font-face {
  font-family: Poppins-Italic;
  src: url(../fonts/Poppins-Italic.ttf);
}
@font-face {
  font-family: Poppins-LightItalic;
  src: url(../fonts/Poppins-LightItalic.ttf);
}


body {
  margin: 0;
  font-family: Poppins-Regular, serif;
}

p {
  margin: 0;
}

.title {
  font-family: Poppins-Regular, sans-serif;
  font-size: $title;
}

.section-container{
  margin-bottom: 18px;
}
.input-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.row-container {
  display: flex;
}

.form-tab-container {
  padding: 16px;
  width: 100%;
  font-family: Poppins-Regular, sans-serif;

}

.geosuggest {
  font-family: Poppins-Regular, serif;
}

.geosuggest__suggests-wrapper {
  position: absolute;
  top: 35px;
  left: 0;
  z-index: 1;
}

.geosuggest__input {
  border: 1px solid black;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
}

.geosuggest__input:focus {
  outline: none;
}

.geosuggest__suggests {
  list-style-type: none;
  background-color: $white;
  font-size: 12px;
  margin: 0 0 5px;
  padding: 0 0 0 8px;
}

.geosuggest__suggests--hidden {
  display: none;
}

.geosuggest__item {
  padding: 4px;
}

.geosuggest__item:hover {
  background-color: #aebfdc44; /* theme.colors.secondary.light with alpha */
  cursor: pointer;
}

.geosuggest__item__matched-text {
  font-family: Poppins-SemiBold, serif;
}

.react-datepicker__input-container input {
  font-family: Poppins-Regular, serif;
  font-size: 14px;
  padding: 5px;
  border: 1px solid black;
  border-radius: 8px;
  outline: none;
}

.error_subtitle {
  font-size: 12px;
  font-family: Poppins-Light, serif;
  font-style: italic;
}