@import "variables";


.expo-item-container {
  width: 100%;
  text-align: left;
  padding: 10px;
  border: 1px solid black;
  border-radius: 20px;
  justify-content: space-between;
  box-shadow: 3px 3px 4px $shadow;
  display: flex;
  outline: none;

  &:hover {
    cursor: pointer;
    background-color: $lightBtn
  }

  &:active {
    background-color: $lightBtnActive
  }
}

.expo-item-header {

  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.expo-item-body {
  display: flex;
  flex-direction: column;
  gap: 4px
}