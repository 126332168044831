@import "variables";

.header-container {
  display: flex;
  justify-content: space-between;
  height: calc(80px - 2 * 10px);
  background-color: $primaryMain;
  padding: 10px;
  align-items: center;
  box-shadow: 0 4px 4px $shadow;
}

.header-title-container {
  display: flex;
  gap: 10px;
  height: 100%;
  align-items: center;
}

.header-logo{
  width: 85%;
  object-fit: cover;
}

.header-title{
  color: $white;
  font-size: 26px;
  align-self: baseline;
}

.header-logo-container {
  background-color: $white;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;

  &:hover {
    opacity: 0.95;
  }

  &:active {
    opacity: 0.9;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.header-nav {
  display: flex;
  gap: 15px;
  list-style: none;
}

.header-icon-btn {
  background-color: transparent;
  border: none;
  outline: none;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}