@import "variables";

.expo-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 600px;
  height: 200px;
}

.expo-modal-type-select {
  display: flex;
  column-gap: 20px;
}

.btn-select{
  border-width: 1px;
  min-width: 100px;
  background-color: $lightBtn;
  color: #000;
  border-color: transparent;

}

.external-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.internal-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select-active {
  background-color: green;
  color: #fff;
  border-color: #000;
}
