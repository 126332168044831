@import "./variables";


.login-root{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  border-radius: 10px;
  border: 1px solid $primaryMain;
  width: 600px;
  padding: 40px;
  display: flex;
  justify-content: space-between;
}

.login-title {
  margin-bottom: 18px;
}


.login-logo-container{
  align-self: center;
}