@import "variables";

.tag-list {
  list-style: none;
  display: flex;
  gap: 4px
}

.tag-item {
  border: 1px solid  $grey;
  padding: 5px;
  border-radius: 10px;
  color: $white;
  background-color: $grey;
}

.tag-archived {
  border: 1px solid $error;
  background-color: $error;
}

.tag-external {
  border: 1px solid blue;
  background-color: blue;
}

.tag-published {
  border: 1px solid green;
  background-color: green;
}

.tag-upcoming {
  border: 1px solid yellowgreen;
  background-color: yellowgreen;
}
