@import "variables";


.btn {
  font-size: $button;
  text-transform: uppercase;
  background-color: $primaryMain;
  color: $white;
  padding: 10px;
  border: 0 solid  $primaryMain;
  border-radius: 10px;
  outline: none;
  &:hover {
    cursor:pointer;
    opacity:0.8;
  }
}
.btn-disabled {
  opacity: 0.6;
  &:hover {
    opacity: 0.6;
    cursor: auto;
  }
}
.btn-delete {
  font-size: 12px;
  text-transform: uppercase;
  background-color: white;
  color: $primaryMain;
  padding: 8px;
  border-width: 1px;
  border-color: $primaryMain;
  border-radius: 8px;
  outline: none;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}