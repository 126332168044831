$primaryLight: #E6D2D8;
$primaryMain: #9e0231;
$primaryDark: #63001E;

$secondaryLight: #AEBFDC;
$secondaryMain: #2D5CAA;
$secondaryDark: #062D6F;
$lightBtnActive : #AEBFDC33;
$lightBtn : #AEBFDC66;
$black: #000000;
$grey: #444444;
$lightGrey: #cccccc;
$white: #ffffff;
$error: #FC693E;
$shadow: #dddddd;



$caption: 12px;
$tooltip: 14px;
$button: 16px;
$buttonChoiceExpoType: 14px;
$main: 16px;
$modalHeader: 24px;
$subtitle: 24px;
$title: 36px;
